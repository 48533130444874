import React, { useState } from "react";
import CarouselSlide from "components/carousel/CarouselSlide";
import CarouselVideoSlide from "components/carousel/CarouselVideoSlide";
import CarouselCloudinaryVideo from "components/carousel/CarouselCloudinaryVideo";
import { motion } from "framer-motion";
import "./Carousel.scss";
import classNames from "classnames";

const Carousel = React.memo(({ slides, animateArticleItem }) => {
    const visibleSlidesAmount = slides.length < 4 ? slides.length : 4;
    const origSlides = [...slides].reverse();

    const activeIndex = 1;
    // eslint-disable-next-line no-unused-vars
    // const [offsetTranslate, setOffsetTranslate] = useState(true);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [activeSlides, setActiveSlides] = useState(
        slides.slice(0, visibleSlidesAmount).reverse()
    );

    const changeSlide = (number) => {
        if (number !== activeSlideIndex) {
            setActiveSlideIndex(number);
            let newActiveSlides = [];
            if (number + visibleSlidesAmount > slides.length) {
                const remainingSlides = slides.slice(
                    0,
                    number - (slides.length - visibleSlidesAmount)
                );
                newActiveSlides = [
                    ...slides.slice(number, slides.length),
                    ...remainingSlides,
                ];
            } else {
                newActiveSlides = slides.slice(
                    number,
                    number + visibleSlidesAmount
                );
            }

            setActiveSlides(newActiveSlides.reverse());
        }
    };

    function slideClick(slide) {
        const reversedIndex = origSlides.findIndex(
            (element) => element.originalId === slide.originalId
        );

        changeSlide(origSlides.length - reversedIndex - 1);
    }

    return (
        <motion.div
            className="desktop-carousel"
            variants={animateArticleItem}
            key="desktop-carousel"
        >
            <div className="desktop-carousel__slides">
                {activeSlides.map((slide, key) => {
                    const slideIndex = visibleSlidesAmount - key;

                    const carouselSlideStates = classNames(
                        `desktop-carousel__slide carousel__slide--${slideIndex}`,
                        {
                            "desktop-carousel__slide--current":
                                slideIndex === activeIndex,
                            "desktop-carousel__slide--video": slide.video,
                            "desktop-carousel__slide--image": slide.image,
                            "desktop-carousel__slide--cloudinary":
                                slide.cloudinaryId,
                        }
                    );

                    const slideProps = {
                        key,
                        className: carouselSlideStates,
                    };

                    let slideComponent = null;

                    if (slide.image) {
                        slideComponent = (
                            <CarouselSlide
                                onClick={() => slideClick(slide)}
                                url={slide.image.fixed.src}
                                alt={slide.image.alt}
                                title={slide.image.title}
                                {...slideProps}
                            />
                        );
                    } else if (slide.video) {
                        slideComponent = (
                            <CarouselVideoSlide
                                onClick={() => slideClick(slide)}
                                thumbnailSrc={slide.video.video.thumbnailUrl}
                                src={slide.video.video.mp4Url}
                                title={slide.image.title}
                                {...slideProps}
                            />
                        );
                    } else if (slide.cloudinaryId) {
                        slideComponent = (
                            <CarouselCloudinaryVideo
                                onClick={() => slideClick(slide)}
                                cloudinaryId={slide.cloudinaryId}
                                caption={slide.caption}
                                {...slideProps}
                            />
                        );
                    }

                    return slideComponent;
                })}
            </div>

            {origSlides.length > 1 && (
                <div className="desktop-carousel__dots">
                    {origSlides.map((slide, key) => {
                        const slideIndex = origSlides.length - key;

                        return (
                            <button
                                key={key}
                                className={`desktop-carousel__dot${
                                    slideIndex ===
                                    origSlides.length - activeSlideIndex
                                        ? " desktop-carousel__dot--current"
                                        : ""
                                }`}
                                onClick={() => changeSlide(key)}
                            >
                                {origSlides.length - slideIndex + 1}
                            </button>
                        );
                    })}
                </div>
            )}
        </motion.div>
    );
});

export default Carousel;
